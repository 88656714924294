<script lang="ts" setup>
const document = ref();
usePageNavigation(document, "document");
const { channels, currentChannel } = useForumChannels();

const navigationStore = useNavigationStore();
navigationStore.pageTitle = "breadcrumb";
</script>

<template>
  <ScrollWrapper>
    <Navigation />
    <div
      id="document"
      ref="document"
      :class="
        cn(
          'w-[100vw] sm:w-[50vw] md:w-[25vw] xl:w-[20rem]',
          'flex flex-col relative shrink-0',
          'snap-start h-full border-r border-strong bg-color-1'
        )
      "
    >
      <div
        :class="cn('sticky z-20 top-0 h-12 border-b', 'flex items-center gap-3 shrink-0 bg-color-1 p-2.5')"
      >
        <h2 class="h2">{{ $t("forum.channels") }}</h2>
      </div>
      <div id="forum-channels" class="grow scroll-area">
        <ul class="flex flex-col">
          <li v-for="channel in channels" :key="channel.name">
            <NuxtLink
              :id="channel.name"
              :to="`/forum/channels/${channel.name}`"
              :class="
                cn('grid grid-cols-5 gap-2.5 p-2.5 border-b hover:bg-color-1 transition-colors', {
                  'bg-color-1': currentChannel?.name === channel.name
                })
              "
            >
              <img class="rounded-md" src="/public/correctiv-logo.png" alt="Channel 1" />
              <div class="col-span-4 flex flex-col gap-1">
                <p class="text-base font-semibold">{{ channel.labelDe }}</p>
                <p class="text-sm text-color-5">{{ channel.descriptionDe }}</p>
              </div>
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
    <ChatRoom
      :title="currentChannel?.[$i18n.locale === 'de' ? 'labelDe' : 'labelEn']"
      class="max-md:w-[100vw] md:max-xl:w-[75vw]"
    />
  </ScrollWrapper>
</template>
