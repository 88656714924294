import { useRoute as useNativeRoute } from "vue-router";

export function useForumChannels() {
  const { data: channels } = useChannelsGet();

  // use vue-router instead of nuxt-router, because nuxt-router is not working correctly after a redirect
  // https://github.com/nuxt/nuxt/issues/21340
  const nativeRoute = useNativeRoute();
  const chatStore = useChatStore();
  const currentChannel = computed(() =>
    channels.value?.find((channel) => channel.name === nativeRoute.params.id)
  );

  watch(
    currentChannel,
    (newChannel) => {
      if (newChannel?.name) {
        chatStore.roomName = newChannel?.name;
      }
    },
    { immediate: true }
  );

  return { channels, currentChannel };
}
